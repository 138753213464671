export default function(
  string = "",
  passCharsLeftNumber = 0,
  passCharsRightNumber = 0
) {
  let passLeft =
    (typeof passCharsLeftNumber === "number" && passCharsLeftNumber >= 0
      ? passCharsLeftNumber
      : 1) - 1;
  let passRight =
    typeof passCharsRightNumber === "number" && passCharsRightNumber >= 0
      ? passCharsRightNumber
      : 0;
  passRight = string.length - passRight;
  return string
    .split("")
    .map((char, i) => (char = i <= passLeft || i >= passRight ? char : "*"))
    .join("");
}
